import {
  Grid,
  Divider,
  makeStyles,
  Paper,
  Typography,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { PrimaryButton } from "../ButtonForm/PrimaryButton";
import NewUserIcon from "../IconsLibrary/NewUserIcon";
import MenuTree from "../../views/menuSettings/menutree";

export const MenuSettingsComponent = ({
  select,
  currentApp,
  values,
  valuesTitle,
  children,
  addInitialDataMenu,
  addInitialDataMenu3,
  saveMenu,
  setOpen,
  MenuSelected,
  areChangesSave,
  onCancelButton,
}) => {
  /* A hook that is used to create a style object. */

  const classes = useStyles();

  return (
    <div>
      <br/>
      <Grid container>
        {currentApp.name !== "" ? (
          <Grid container spacing={2}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              {select}
            </Grid>
            <Grid container item lg={8} md={12} sm={12} xs={12}>
              <Paper className={classes.paperTable} style={{ padding: "20px" }}>
                <Grid container>
                  <Grid item lg={11} md={11} sm={11} xs={11}>
                    <Typography className={classes.titleSelectTypeMenu}>
                      {currentApp.name}
                    </Typography>
                  </Grid>
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                    <Tooltip title={`Crear Menu`} aria-label={`Nuevo Menu`}>
                      <NewUserIcon
                        className={classes.plusIcon}
                        onClick={() => {
                          setOpen(true);
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Typography className={classes.subtitleSelectTypeMenu}>
                      Recuerda que puedes ordenar el menú arrastrando y soltando
                      cada ítem a la posición que prefieras.
                    </Typography>
                  </Grid>
                </Grid>

                {/* Este parte solamente es para Menu 1 y 2 */}
                {MenuSelected === 1 ? (
                  <div>
                    <Typography variant="h6">{valuesTitle}</Typography>
                    {values.menu1.length !== 0 ? (
                      <MenuTree
                        treeData={values.menu1}
                        setTreeData={addInitialDataMenu}
                        idMenu={1}
                      />
                    ) : (
                      <span>No hay datos para mostrar</span>
                    )}
                    {children}
                  </div>
                ) : null}

                {/* Este parte solamente es para Menu 3 */}
                {MenuSelected === 3 ? (
                  <div>
                    <Typography variant="h6" className={classes.titleSelectTypeMenu}>{"Sección aplicación"}</Typography>
                      {values.menu3 &&
                      values.menu3.application.length !== 0 ? (
                        <MenuTree
                          property={"application"}
                          treeData={values.menu3.application}
                          setTreeData={addInitialDataMenu3}
                          idMenu={3}
                        />
                      ) : (
                        <span>No hay datos para mostrar</span>
                    )}
                    <Divider style={{ height: "1px", width: "94%", marginTop:"25px", marginBottom: "25px" }} />
                    <Typography variant="h6" className={classes.titleSelectTypeMenu}>{"Sección usuario"}</Typography>
                    {values.menu3 &&
                    values.menu3.user.length !== 0 ? (
                      <MenuTree
                        property={"user"}
                        treeData={values.menu3.user}
                        setTreeData={addInitialDataMenu3}
                        idMenu={3}
                      />
                    ) : (
                      <span>No hay datos para mostrar </span>
                    )}
                  </div>
                ) : null}
              </Paper>
            </Grid>
          </Grid>
        ) : null}
        <Grid container style={{ marginBottom: 20 }}>
          <Grid
            container
            item
            justifyContent="center"
            lg={8}
            md={12}
            sm={12}
            xs={12}
          >
            <PrimaryButton
              text="No guardar"
              disabled={currentApp.name === "" || !areChangesSave ? true : false}
              onClick={onCancelButton}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <PrimaryButton
              text="Guardar menú"
              disabled={currentApp.name === "" || !areChangesSave ? true : false}
              onClick={saveMenu}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  titleSelectTypeMenu: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
    marginTop: "10px",
    marginBottom: "10px",
  },
  subtitleSelectTypeMenu: {
    color: theme.palette.primary.light,
    fontSize: theme.typography.pxToRem(12),
    marginTop: "10px",
    marginBottom: "10px",
  },
  divider:{
    height: "1px", 
    width: "94%", 
    marginTop:"25px", 
    marginBottom: "25px"
  },
  paperTable: {
    width: "auto",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.primary.light}`,
    marginBottom: 20,
  },
  plusIcon: {
    height: "32px !important",
    width: "32px !important",
    stroke: theme.palette.secondary.main,
  },
  itemThree: {
    height: 40,
    minWidth: 350,
  },
}));
