import { Grid, Paper, Typography, makeStyles, Tooltip } from "@material-ui/core";
import React, { useState, forwardRef, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAppsForMenu } from "../../../actions/applications.action";
import { GET_MENU_CHANGE } from "../../../actions/types";
import { useDrag } from "../../../hooks/useDrag";
import { GridDrag } from "../../../views/menuSettings/GridDrag";
import ControlledSelect from "../../InputForm/ControlledSelect";
import FullLoader from "../../Loader/FullLoader.component";
import { PrimaryButton } from "../../ButtonForm/PrimaryButton";
import { UseDialog } from "@dg-bucaramanga/react-components-dg-pre";
import { createMenu,GetMenuByApp,ModifyMenu,} from "../../../actions/menu.action";
import { ModalFormSuite } from "../../../views/menuSettings/ModalFormSuite";
import NewUserIcon from "../../IconsLibrary/NewUserIcon";
import { generateKeyId, validateEquals } from "../../../utils/proprietaryHooks";

export let appliSuiteMenu = "";

//Drag and Drop
export const SuiteMenuSetting = forwardRef((props, ref) => {
  const { menuLocation, menu, onCancelButton } = props;
  const { currentMenu, setCurrentMenu, idMenu, setCurrentMenuDragDrop } = menu;

  const [open, setOpen] = useState(false);

  /**
   * Valores Iniciales del MenuJson del objeto menu
   */
  const initialValues = {
    name: "",
    urlLanding: "",
    urlApplication: "",
    isActivate: false,
    description: "",
    iconName: "",
    iconNameDark: "",
  };
  
  const modalRef = useRef(null);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [shouldUpdateMenu, setShouldUpdateMenu] = useState(false);

  const [dialog, setDialog] = React.useState({
    bodyText: "",
    cancelText: "",
    acceptText: "",
    action: "",
  });
  const [isUpdatedItem, setIsUpdatedItem] = useState(false);
  const [isUpdatedItemFlag, setIsUpdatedItemFlag] = useState(false);

  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      application: "",
      state: false,
    },
  });
  const { control, watch } = form;
  const [applications, setApplications] = React.useState([]);
  const applicationsResponse = useSelector(({ applicationsReducer }) => {
    return applicationsReducer.getApplicationsMenuResponse;
  });
  const [currentApp, setCurrentApp] = React.useState({
    name: "",
    id: 0,
    items: [],
  });
  const {
    values: suiteApplications,
    dragStart,
    dragOver,
    drop,
    addInitialData,
    addItem,
  } = useDrag();
  const dispatch = useDispatch();

  const customAction = {
    delete: "Delete",
    edit: "Edit",
    state: "State",
  };

  useEffect(() => {
    addInitialData(menu.currentMenu);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu.currentMenu]);

  useEffect(() => {
    if (!applicationsResponse.length) {
      dispatch(getAppsForMenu());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!applications.length && applicationsResponse.length) {
      setApplications(applicationsResponse);
    }
  }, [applications.length, applicationsResponse, setApplications]);

  useEffect(() => {
    const app = watch("application");
    appliSuiteMenu = app;
    if (app !== "" && app !== undefined) {
      setCurrentMenu([]);
      addInitialData([]);
      dispatch(GetMenuByApp(app, menuLocation));
      setIsUpdatedItem(false);
      setIsUpdatedItemFlag(false);
      setCurrentApp((oldState) => ({
        ...oldState,
        name: applications.find((item) => item.id === app).name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("application")]);

  useEffect(() => {
    if(shouldUpdateMenu){
      dispatch(GetMenuByApp(watch("application"), menuLocation));
      setShouldUpdateMenu(false);
    }
  }, [shouldUpdateMenu, dispatch, watch, menuLocation]);

  //true para verse
  useEffect(() => {
    if (validateEquals(currentMenu, suiteApplications)) {
      if (isUpdatedItemFlag) {
        setIsUpdatedItem(true);
      } else {
        setIsUpdatedItem(false);
      }
      setCurrentMenuDragDrop([]);
    } else {
      setCurrentMenuDragDrop(suiteApplications);
      setIsUpdatedItem(true);
    }
  }, [currentMenu, suiteApplications, setIsUpdatedItem, isUpdatedItemFlag, setCurrentMenuDragDrop]);

  /**
   * Maneja la acción cuando se pulsa un botón en un elemento.
   * @param {string} key - La clave del elemento.
   * @param {string} action - La acción a realizar.
   * @param {object} section - La sección donde se encuentra el elemento.
   * @param {MouseEvent} event - El evento del ratón.
   */
  const itemAction = (key, action, section, event) => {
    async function getMenus() {
      await new Promise(GetMenuByApp(watch("application"), menuLocation));
    }
    getMenus();
    switch (action) {
      case customAction.delete:
        setDialog({
          bodyText: "¿Deseas eliminar este ítem?",
          cancelText: "No",
          acceptText: "Sí",
          action: key,
        });
        onOpen();
        break;
      case customAction.edit:
        //section.isActivate = true;
        setInitialFormValues(section);
        setOpen(true);
        break;
      default:
        break;
    }
  };

  const handleConfirmClick = () => {
    onAcceptClose();
    const newData = searchItemUpdateAndDelete(dialog.action, suiteApplications, "Delete");
    saveMenu(true, newData, "El ítem ha sido eliminado");
  };

  const {
    Dialog, 
    onOpen,
    onClose: onAcceptClose,
  } = UseDialog({
    bodyText: dialog.bodyText,
    cancelButtonText: dialog.cancelText,
    confirmationButtonText: dialog.acceptText,
    onConfirmClick: handleConfirmClick,
  });

  /**
   *  save each change on items
   * @param {*} iconName
   * @param {*} isEdit
   * @param {*} itemValues
   */
  const saveItem = (action, itemValues) => {
    setOpen(false);
    let values = {};
    if (action) {
      const menuValues = suiteApplications;
      const searchValues = {
        edit: { ...itemValues },
        delete: {},
      };
      const arrayOfObject = Object.entries(searchValues[action.toLowerCase()]).sort();
      const orderedObject = Object.fromEntries(arrayOfObject);
      const newMenu = searchItemUpdateAndDelete(
        itemValues.key,
        menuValues,
        action,
        orderedObject
      );
      addInitialData(newMenu);
      setCurrentMenu(newMenu);
    } else {
      // create item
      const { getValues } = modalRef.current.form;
      values = getValues();
      const item = {
        application: watch("application"),
        ...values,
        key: generateKeyId(10),
        menuLocation: menuLocation,
      };
      const arrayOfObject = Object.entries(item).sort();
      const orderedObject = Object.fromEntries(arrayOfObject);
      const previousMenu = [...currentMenu, orderedObject];
      setCurrentMenu(previousMenu);
      addItem(orderedObject);
    }
    handleClose();
    setIsUpdatedItem(true);
    setIsUpdatedItemFlag(true);
  };

  const handleClose = () => {
    setInitialFormValues(initialValues);
    modalRef.current.restartModal();
  };

  const searchItemUpdateAndDelete = (key, initialData, action, newData) => {
    let dataResponse = [];
    for (const dat of initialData) {
      if (action === "Delete") {
        if (dat.key !== key) {
          dataResponse.push(dat);
        }
      } else {
        if (dat.key === key) {
          dataResponse.push({ ...newData });
        } else {
          dataResponse.push(dat);
        }
      }
    }
    return dataResponse;
  };

  const saveMenu = async (useHooksData, data, message) => {
    setLoading(true);
    useHooksData = typeof useHooksData !== "boolean" ? false : useHooksData;
    let valuesComplete = useHooksData
      ? data
      : suiteApplications;
    if (idMenu === 0) {
      const objectCreate = {
        id: idMenu,
        idapplication: watch("application"),
        idmenulocation: menuLocation,
        numberRows: menuLocation === 4 ? 4 : 0,
        menujson: JSON.stringify(removeTitle(valuesComplete)),
      };
      try {
        await new Promise(createMenu(objectCreate));
      } catch (error) {
        console.error(error);
      }
      setDialog({
        bodyText: "¡Menú guardado exitosamente!",
      });
      onOpen();
      setShouldUpdateMenu(true);
      setLoading(false);
      dispatch({
        type: GET_MENU_CHANGE,
        payload: {},
      });
    } else {
      const objectModify = {
        id: idMenu,
        idapplication: watch("application"),
        idmenulocation: menuLocation,
        numberRows: menuLocation === 4 ? 4 : 0,
        menujson: JSON.stringify(removeTitle(valuesComplete)),
      };
      try {
        await new Promise((ModifyMenu(objectModify)));
      } catch (error) {
        console.error(error);
      }
      if (message) {
        setDialog({
          bodyText: message,
        });
      } else {
        setDialog({
          bodyText: "¡Menú modificado exitosamente!",
        });
      }
      onOpen();
      setShouldUpdateMenu(true);
      setLoading(false);
      dispatch({
        type: GET_MENU_CHANGE,
        payload: {},
      });
    }
    setIsUpdatedItem(false);
    setIsUpdatedItemFlag(false);
    if (useHooksData) {
      addInitialData(data);
      setCurrentMenu(data);
    }
  };

  const removeTitle = (menus) => {
    let objectMenus = [];
    if (menus !== undefined && menus.length > 0) {
      for (const menu of menus) {
        let objectMenuChildren = [];
        if (menu.children !== undefined && menu.children.length > 0) {
          objectMenuChildren = removeTitle(menu.children);
        }
        const { title, ...rest } = menu;
        if (objectMenuChildren.length !== 0) {
          objectMenus.push({ ...rest, children: objectMenuChildren });
        } else {
          objectMenus.push(rest);
        }
      }
    }
    return objectMenus;
  };

  const cancelButton = () => {
    setIsUpdatedItem(false);
    setIsUpdatedItemFlag(false);
    onCancelButton(menuLocation);
  };

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <FullLoader open={loading} />
      ) : (
        <div style={{ width: '100%' }}>
          <br />
          <Grid container>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <ControlledSelect
                control={control}
                id="application"
                name="application"
                options={applications}
                label="Aplicación"
              />
            </Grid>
          </Grid>
          <div>
            <br />
            <Grid container>
              {currentApp.name !== "" ? (
                <Grid container spacing={2}>
                  <Grid container item lg={8} md={12} sm={12} xs={12}>
                    <Paper
                      className={classes.paperTable}
                      style={{ padding: "20px" }}
                    >
                      <Grid container>
                        <Grid item lg={11} md={11} sm={11} xs={11}>
                          <Typography className={classes.titleSelectTypeMenu}>
                            {currentApp.name}
                          </Typography>
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} xs={1}>
                          <Tooltip title={`Crear Menu`} aria-label={`Nuevo Menu`}>
                            <NewUserIcon
                              className={classes.plusIcon}
                              onClick={() => {
                                setOpen(true);
                              }}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                          <Typography
                            className={classes.subtitleSelectTypeMenu}
                          >
                            Recuerda que puedes ordenar el menú arrastrando y
                            soltando cada aplicación a la posición que
                            prefieras.
                          </Typography>
                        </Grid>
                      </Grid>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <GridDrag
                          ref={modalRef}
                          values={suiteApplications}
                          dragStart={dragStart}
                          dragOver={dragOver}
                          drop={drop}
                          itemAction={itemAction}
                          saveMenu={saveMenu}
                        />
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container style={{ marginBottom: 20 }}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  lg={8}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <PrimaryButton
                    text="No guardar"
                    disabled={currentApp.name === "" || !isUpdatedItem ? true : false}
                    onClick={cancelButton}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <PrimaryButton
                    text="Guardar menú"
                    disabled={currentApp.name === "" || !isUpdatedItem ? true : false}
                    onClick={saveMenu}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
           <ModalFormSuite
            initialValues={initialFormValues}
            ref={modalRef}
            open={open}
            setOpen={setOpen}
            handleSave={saveItem}
            handleClose={handleClose}
            appName={currentApp}
          />
          <Dialog />
        </div>
      )}
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  titleSelectTypeMenu: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
    marginTop: "10px",
    marginBottom: "10px",
  },
  subtitleSelectTypeMenu: {
    color: theme.palette.primary.light,
    fontSize: theme.typography.pxToRem(12),
    marginTop: "10px",
    marginBottom: "10px",
  },
  paperTable: {
    width: "100%",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.primary.light}`,
    marginBottom: 20,
  },
  plusIcon: {
    height: "32px !important",
    width: "32px !important",
    stroke: theme.palette.secondary.main,
  },
  itemThree: {
    height: 40,
    minWidth: 350,
  },
}));
