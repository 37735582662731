import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import CustomInput from './CustomInput';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme, Typography, Box, Link } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PrimaryButtonUko from '../primaryButtonUko.component';
import { SendUserActivationEmail, resetPassword } from '../../actions/auth.action';
import PropTypes from "prop-types";
import ErrorBoxUko from '../errorBoxUko.component';
import { getVersionAppAction } from "../../actions/applications.action";
import { isEmpty } from "../../utils/proprietaryHooks";


const LayouthInfoUko = (props) => {

    const theme = useTheme();
    const history = useHistory();

    const [isValidEmail, setIsValidEmail] = useState(false);
    const [currentEmail, setCurrentEmail] = useState("");
    const [isLoadinButton, setIsLoadinButton] = useState(false);
    const [versionMessage, setVersionMessage] = useState("");

    const {
        appName,
        firstImage,
        title,
        secondImage,
        widthOfSecondImage,
        infoText,
        hasInput,
        textBeforeEnd,
        textInTheEnd,
        onClickLink,
        textInLink,
        resetPassword,
        getVersionAppAction,
        getVersionApp,
    } = props;

    const standarStyles = {
        firstText: {
            fontWeight: "700",
            fontStyle: 'normal',
            fontSize: "28px",
            marginTop: "20px",
            color: theme.palette.secondary.titleText,
        },
        versionApp: {
            position: 'absolute',
            bottom: '5px',
        }
    }

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .max(100)
            .required("El Campo email es obligatorio")
            .email('El formato de mail ingresado no es válido. Inténtalo de nuevo.')
            .matches(/^[\w]+([.\-+][\w]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4}){1,3}$/,
                'El formato de mail ingresado no es válido. Inténtalo de nuevo.')
    });

    const {
        control,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            email: "",
        },

        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (appName && appName !== "") {
            getVersionAppAction(appName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isEmpty(getVersionApp)) {
            setVersionMessage(getVersionApp.version);
        }
    }, [getVersionApp]);

    const handleChangeOfEmail = (event) => {
        const emailFromInput = event.target.value;
        validationSchema.isValid({ email: emailFromInput })
            .then(function (valid) {
                setIsValidEmail(valid);
                setCurrentEmail(emailFromInput);
            });
    }

    const getErrors = () => {
        if (errors.email) {
            return <ErrorBoxUko
                value={errors.email.message}>
            </ErrorBoxUko>
        }
    };

    const handleResetPassword = async () => {
        setIsLoadinButton(true);
        await resetPassword({
            user: currentEmail,
            appname: appName,
        }, history, "/Alert");
    }

    /**
     * This function send a email to activate account
     */
    const handleClickInLink = async () => {
        onClickLink();
        sendEmailIfIsNecesay();
    }

    const senEmailToUser = async (userName) => {
        localStorage.setItem('resend', "false");
        dispatch(SendUserActivationEmail({
            userName,
            appName,
        }));
    }

    /**
     * This function verify if user saved in local storge is not null and send email
     */
    const sendEmailIfIsNecesay = () => {
        const userName = history.location.state?.userName;
        if (userName !== undefined ) {
            senEmailToUser(userName);
        }
    }

    useEffect(() => {
        sendEmailIfIsNecesay();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.mainComponent}
        >
            <Box m={1} pt={3}>
                <img src={theme.urlLogo[`${firstImage}`]} alt="Logo" width="115.71px" />
            </Box>
            <h1 style={standarStyles.firstText}>
                {title}
            </h1>
            <Box m={1} pt={3}>
                <img src={theme.urlLogo[`${secondImage}`]} alt="Logo" width={widthOfSecondImage !== '' ? widthOfSecondImage : '225px'} />
            </Box>
            {hasInput &&
                <div>
                    <Box m={1} pt={3}>
                        <Typography className={classes.infoText}>
                            {infoText}
                        </Typography>
                    </Box>
                    <Box m={1} pt={3}>
                        <CustomInput
                            label="Email"
                            name="email"
                            control={control}
                            styleFromHelperText={{ style: { marginRight: 10 } }}
                            error={errors['email'] ? {
                                email: {
                                    message: "",// This message is empty because other component show errors.
                                    type: "required"
                                }
                            } : {}}
                            classes={classes.typeInput}
                            autoFocus={true}
                            onValidate={handleChangeOfEmail}
                        />
                    </Box>
                    {getErrors()}
                    <Box m={1} pt={3} width={487}>
                        {/* TODO: Change Width more less, for responsive!! */}
                        <PrimaryButtonUko
                            txtBtn={"Restablecer contraseña"}
                            loading={isLoadinButton}
                            disabled={!isValidEmail}
                            onClick={handleResetPassword}
                        />
                    </Box>
                </div>
            }
            {textBeforeEnd &&
                <Box m={1} pt={3}>
                    <Typography className={classes.sendEmailMessage}>
                        {textBeforeEnd}
                    </Typography>
                </Box>
            }
            {textInTheEnd &&
                <Box m={1} pt={3} fontWeight='fontWeightMedium' >
                    <Typography className={classes.sendEmailMessage}>
                        {textInTheEnd}
                    </Typography>
                </Box>
            }
            {onClickLink && textInLink ?
                <Box m={1} pt={3}>
                    <Link
                        onClick={handleClickInLink}
                        className={classes.textLink}
                    >
                        {textInLink}
                    </Link>
                </Box>
                :
                <></>
            }
            <Box className={classes.versionApp}>
                <p>{versionMessage}</p>
            </Box>
        </Grid>
    );
}

LayouthInfoUko.propTypes = {
    resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    loadingReducer: state.loadingReducer,
    getAuthenticationResponse: state.userReducer.getUserChangedResponse,
    getVersionApp: state.applicationsReducer.getVersionApp,
});

const mapDispatchToProps = {
    resetPassword,
    getVersionAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayouthInfoUko);


const useStyles = makeStyles((theme) => ({
    typeInput: {
        '& .MuiOutlinedInput-root': {
            position: 'relative',
            borderRadius: '8px',
            width: '486px',
            height: '56px',
            backgroundColor: theme.InputUko.backgroundColor,
        },
        "& .MuiOutlinedInput-input": {
            fontWeight: 500,
            color: theme.InputUko.colorInputLetter,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
            borderColor: theme.InputUko.borderInput,
        },
        "& .MuiInputLabel-root": {
            fontWeight: 500,
            color: theme.InputUko.colorLabel,
            fontSize: '17px',
            marginTop: '7px',
        },
        "& .MuiInputLabel-root.Mui-focused": {
            fontWeight: 600,
            color: theme.InputUko.colorLabelFocus,
        },
        "& .MuiSvgIcon-root": {
            color: theme.InputUko.colorIcon,
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error.dark,
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: theme.palette.error.dark,
        },
        "& .MuiInputLabel-shrink": {
            margin: "0 auto",
            position: "absolute",
            top: "1px",
        },
    },
    version: {
        fontSize: '14px',
        fontWeight: 500,
        color: theme.versionUko.colorVersionLabel,
        bottom: '15px',
        left: '50%',
        right: '0',
        textAlign: 'center',
        position: 'static',
    },
    sendEmailMessage: {
        color: theme.palette.info.light,
        fontSize: '15px',
        fontWeight: 700,
    }, textLink: {
        textDecoration: "underline",
        color: theme.linkUko.colorLink,
        marginBottom: '2px',
        fontSize: '15px',
        fontWeight: 700
    }, mainComponent: {
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.light,
    }, infoText: {
        color: theme.palette.info.light,
        fontSize: '15px',
        fontWeight: 700,
    }, versionApp: {
        fontSize: '14px',
        fontWeight: 600,
        color: theme.versionUko.colorVersionLabel,
        position: 'absolute',
        bottom: '15px',
        textAlign: 'center',
    }
}));

