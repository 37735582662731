import React, { useState } from "react";
import { Tree } from "antd";
import "antd/dist/antd.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .ant-tree-switcher": {
      alignSelf: "center",
    },
  },
}));

const MenuTree = ({ treeData, setTreeData, property, idMenu }) => {

  const [checkedKeys, setCheckedKeys] = useState([]);
  const classes = useStyles();
  const onDragEnter = (info) => { };

  const onDrop = (info) => {
    
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...treeData];
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setTreeData(data, property);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  return (
    <>
      <Tree
        className={["draggable-tree", classes.root]}
        draggable={{icon: false}}
        onCheck={onCheck}
        selectable={false}
        checkedKeys={checkedKeys}
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        treeData={treeData}
      />
    </>
  );
};

export default MenuTree;
