import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  useTheme,
  Grid,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Box from '@material-ui/core/Box';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ErrorBoxUko from "../../../components/errorBoxUko.component";
import AuthenticationLayoutUko from "../../../components/AuthenticationLayoutUko/AuthenticationLayoutUko";
import CustomInput from "../../../components/AuthenticationLayoutUko/CustomInput";
import PrimaryButtonUko from "../../../components/primaryButtonUko.component";
import { makeStyles } from "@material-ui/core/styles";
import { string } from "prop-types";
import { resetPassword, clearAuthenticationResponse } from "../../../actions/auth.action";
import { getVersionAppAction } from "../../../actions/applications.action";
import { isEmpty } from "../../../utils/proprietaryHooks";

const ResetPasswordUko = (props) => {  
  const {
    resetPassword,
    appName,
    getAuthenticationResponse,
    getVersionAppAction,
    getVersionApp,
  } = props;

  const [errorsValidation, setErrorsValidation] = useState({});
  const [validateCallBack, setValidateCallBack] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [versionMessage, setVersionMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({    
    email: Yup.string()
      .max(100)
      .required("Ingresa un email valido")
      .email('El formato de email ingresado no es válido. Inténtalo de nuevo.')
      .matches(/^[\w]+([.\-+][\w]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4}){1,3}$/, 
      'El formato de email ingresado no es válido. Inténtalo de nuevo.')
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
    
    resolver: yupResolver(validationSchema),
  });
  const watchEmail = watch('email');
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const setResetPassword = async (data) => {     
    let userData = {
      user: data.email ,
      appname: appName,      
    };
    setLoading(true);
    resetPassword(userData, history);    
  };

  /**
   * funcion expresion validación de correo
   */
  const validEmail = new RegExp(/^[\w]+([.\-+][\w]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4}){1,3}$/);

  /**
   * 
   * @returns retorna error presentado en validación
   */
  const getErrors = () => {
    if (!errors.lenght > 0) {
      let errorMessage = string.empy;
      if (errors.email?.message !== "") {
        errorMessage = errors.email?.message;
      }
      if (errorMessage && watchEmail !== "") {
        return <ErrorBoxUko
        value={errorMessage}></ErrorBoxUko>
      }      
    }

    if (errorsValidation?.email && validateCallBack){
      const messageValue = errorsValidation?.email?.message;
      return <ErrorBoxUko
        value={messageValue}></ErrorBoxUko>
    }
  };

  const handleOnValidate = (e) => {    
    setValidateCallBack(false);
    clearAuthenticationResponse();
    const email = e.target.value;

    if (!validEmail.test(email)) {
      setDisabledButton(true);
    }
    else {
      setDisabledButton(false);
    }
  }

  useEffect(() => {
    clearAuthenticationResponse();
    getVersionAppAction(appName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!!getAuthenticationResponse) {
      if (getAuthenticationResponse.responseCode === 200) {
        setValidateCallBack(false);
        setDisabledButton(false);
      } else {
        setLoading(false);
        setDisabledButton(true);
        setValidateCallBack(true);        
        console.log('getAuthenticationResponse.message', getAuthenticationResponse.message);
        setErrorsValidation({
          email: {
            message: getAuthenticationResponse.message,
            type: "invalid-user"
          }
        });          
      }
    }
  }, [getAuthenticationResponse]);

  useEffect(() => {
    if (!isEmpty(getVersionApp)) {
      setVersionMessage(getVersionApp.version);
    }
  }, [getVersionApp]);

  return (
    <AuthenticationLayoutUko title="¿Olvidaste tu contraseña?" logoApp={theme.urlLogo.urlRememberPasswordlogo} logoSignIn={theme.urlLogo.urlSignIn} >
      <form id="resetPasswordUko-validation" autoComplete="off">
        <Grid container direction="column" className={classes.gridContainer}>          
          <Grid item xs={12} className={classes.gridItem}>            
            <Box>
              <Typography className={classes.sendEmailMessage}>          
                ¡No hay problema! ingresa tu email para restablecer tu contraseña
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <CustomInput
              label="Email"
              name="email"
              control={control}
              error={errors?.email ? errors : errorsValidation}
              required
              styleFromHelperText={{ style: { display: 'none' } }}
              classes={classes.typeInput}
              autoFocus={true}
              onValidate={handleOnValidate}
            />
          </Grid>
          {getErrors()}
          <PrimaryButtonUko
            txtBtn={"Restablecer contraseña"}            
            disabled={disabledButton}
            loading={loading} 
            onClick={handleSubmit(setResetPassword)}
          />
        </Grid>
      </form>
      <div classes={classes.divSpace} ></div>
      <Grid item xs={12} className={`${classes.gridItem}`}>
        <Typography
           className={classes.typographyGoBack}
          >
          <Link
            to="/login"
            className={classes.linkGoBack}
          >
            Iniciar sesión
          </Link>
        </Typography>
      </Grid>
      <Box className={classes.version}>
        <p>{versionMessage}</p>
      </Box>
    </AuthenticationLayoutUko>
  );
};

ResetPasswordUko.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
  getAuthenticationResponse: state.authReducer.getAuthenticationResponse,
  getVersionApp: state.applicationsReducer.getVersionApp, 
});

const mapDispatchToProps = {
  resetPassword,
  clearAuthenticationResponse,
  getVersionAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordUko);

const useStyles = makeStyles((theme) => ({
  typeInput: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '8px',
      width: '486px',
      height: '56px',
      backgroundColor: theme.InputUko.backgroundColor,
      [theme.breakpoints.down("xs")]: {
        width: "320px",
      },
    },
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.InputUko.colorInputLetter,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      borderColor: theme.InputUko.borderInput,
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      color: theme.InputUko.colorLabel,
      fontSize: '17px',
      marginTop: '7px',
    },
    "& .MuiInputLabel-root.Mui-focused": {
      fontWeight: 600,
      color: theme.InputUko.colorLabelFocus,
    },
    "& .MuiSvgIcon-root": {
      color: theme.InputUko.colorIcon,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.dark,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.error.dark,
    },
    "& .MuiInputLabel-shrink": {
      margin: "0 auto",
      position: "absolute",
      top: "1px",
    },
  },
  gridContainer: {
    gap: "16px",
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },
  gridItem: { 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center" 
  },
  divSpace: { 
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between', 
    marginTop: '24px',
    marginBottom: '24px'
  },
  sendEmailMessage: {
    color: theme.typography.colorMessageUko,
    fontSize: '15px',
    fontWeight: 500,
  },
  typographyGoBack: { 
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 1.6,
    color: theme.linkUko.colorLink,
    paddingLeft: '8px',
    paddingRight: '8px',
    marginTop: '15px'
  },
  linkGoBack: {     
    textDecoration: "underline",
    color: theme.linkUko.colorLink,
    marginBottom: '2px',
    fontSize: '15px',
    fontWeight: 700
  },
  version: {
    fontSize: '14px',
    fontWeight: 600,    
    color: theme.versionUko.colorVersionLabel,
    [theme.breakpoints.up("md")]: {
      position: 'absolute',
    },
    bottom: '15px',
    left: '50%',
    right: '0',
    textAlign: 'center',
  }
}));

